import React from "react";
import "./service.css"
const ServicesCard =({title})=>{
    return(
        <div class="content">
  <h1 class="title">
    <span class="title-part line-5">{title}</span>
  </h1>
</div>


    )
}
    export default ServicesCard;