import React from "react"
import Slider from "../Components/Slider/Slider"
import AboutCard from "../Components/About/AboutCard"
import ServicesCard from "../Components/services/servicesCard"
import MiniSlider from "../Components/Slider/MiniSlide"


const Apropos = () => {
    return(<>
 <section className='back11'>
    <div className="boxB">
</div> 
  </section>
    <AboutCard/>
    <ServicesCard/>
    </>
)
}
export default Apropos